import './App.css';

import React, { Component, Suspense } from 'react';
import PropTypes from 'prop-types';
import { BrowserRouter, Routes, Route } from 'react-router-dom';

import history from '../../history';

import Loader from '../Loader';
import ErrorBoundary from '../ErrorBoundary';

import HeaderContainer from '../../containers/HeaderContainer';
import InfoModal from '../InfoModal';

import {
  ShowJSONContainer,
  ImportJSONContainer,
  MainContainer,
} from '../../lazy';
import GlobalLoader from '../GlobalLoader';
import { checkIsEqualConfigLocalAndServer } from '../../utils';

class App extends Component {
  componentDidMount() {
    const { fetchConfigRequest, getJwtDataRequest } = this.props;
    getJwtDataRequest(
      fetchConfigRequest,
    );
  }

  componentDidUpdate() {
    const { configData, serverConfig } = this.props;

    window.onbeforeunload = event => {
      if (event && !checkIsEqualConfigLocalAndServer(configData, serverConfig)) {
        event.preventDefault();
        const message = 'Are you sure you want to leave? You have unsaved changes.';
        return event.returnValue = message;
      }
    };
  }

  render() {
    const {
      modalTitle, isModalVisible, modalBody, modalType, hideModal, handleModalCloseButton, isLoaderVisible, modalCloseButtonText,
      modalContinueButtonText, handleModalContinueButton, modalClassName,
    } = this.props;

    return (
      <ErrorBoundary>
        <BrowserRouter history={history}>
          <div className="App">
            <InfoModal
              isModalVisible={isModalVisible}
              handleModalClose={hideModal}
              modalType={modalType}
              modalTitle={modalTitle}
              modalBody={modalBody}
              handleModalCloseButton={handleModalCloseButton}
              modalCloseButtonText={modalCloseButtonText}
              modalContinueButtonText={modalContinueButtonText}
              handleModalContinueButton={handleModalContinueButton}
              modalClassName={modalClassName}
            />
            <GlobalLoader isLoaderVisible={isLoaderVisible} />
            <HeaderContainer />
            <Suspense fallback={<Loader />}>
              <Routes>
                <Route exact path="/" element={<MainContainer />} />
                <Route path="/show-json" element={<ShowJSONContainer />} />
                <Route path="/import-json" element={<ImportJSONContainer />} />
              </Routes>
            </Suspense>
          </div>
        </BrowserRouter>
      </ErrorBoundary>
    );
  }
}

App.defaultProps = {
  handleModalCloseButton: () => {},
  handleModalContinueButton: () => {},
  modalCloseButtonText: '',
  modalContinueButtonText: '',
  modalClassName: '',
};

App.propTypes = {
  activeTenant: PropTypes.shape({
    label: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
  }).isRequired,
  fetchConfigRequest: PropTypes.func.isRequired,
  hideModal: PropTypes.func.isRequired,
  isModalVisible: PropTypes.bool.isRequired,
  modalBody: PropTypes.any.isRequired,
  modalType: PropTypes.string.isRequired,
  modalTitle: PropTypes.string.isRequired,
  handleModalCloseButton: PropTypes.func,
  isLoaderVisible: PropTypes.bool.isRequired,
  modalCloseButtonText: PropTypes.string,
  modalContinueButtonText: PropTypes.string,
  handleModalContinueButton: PropTypes.func,
  serverConfig: PropTypes.object.isRequired,
  configData: PropTypes.object.isRequired,
  modalClassName: PropTypes.string,
  getJwtDataRequest: PropTypes.func.isRequired,
};

export default App;
